import React from "react";

import './Header.css';

export const Header = () => (
    <>
        <div className="header">
        <h1>Zarządzanie listą odpowiedzi</h1>
        </div>
    </>
)